import React from 'react'

const Cover = ({ mag }) => {

    const { month, year, img, url } = mag


    return (
        <div className='cover'>
            <div className="cover__header">
                <div class="heading">{month}</div>
                <div class="heading">{year}</div>
            </div>
            <div className="cover__img">
                <img src={img} alt={`Тюремный вестник. ${month} ${year}.`} loading="lazy" />
            </div>
            <a href={url} className="btn btn-cover" target='_blank'>
                посмотреть
            </a>
        </div>
    )
}

export default Cover
