import React from 'react'
import { useRef } from 'react'
import Accordion from '../components/Accordion'
import Cover from '../components/Cover'
import Popup from '../components/Popup'

import Arrowhead from '../media/arrowhead.svg'
import MobileHead from '../media/main_mobile.png'
import Sending from '../media/sending.jpg'
import Author from '../media/author.jpg'
import AuthorName from '../media/author_name.svg'
import Stickers from '../media/stickers.jpg'
import mags from '../media/mags'

const Mobile = () => {

    const magsBlock = useRef(null)
    const infoBlock = useRef(null)

    const goToMags = () => {
        magsBlock.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const goToInfo = () => {
        infoBlock.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='mobile__container'>
            <div className="mobile__header">
                <img src={MobileHead} alt="" loading="lazy" />
                <div className='mobile__header__content'>
                    <div>
                        «Тюремный вестник» — это новостной дайджест событий за месяц для политзаключённых,
                        написанный нормальным языком. С&nbsp;мемами и&nbsp;подмигиванием, а&nbsp;также с&nbsp;великолепной обложкой,
                        где отображено всё, что произошло за&nbsp;30&nbsp;дней.
                    </div>
                    <div className="btn__container">
                        <div className='btn btn-main' onClick={goToInfo}>узнать больше о проекте</div>
                        <div className="btn btn-main" onClick={goToMags}>смотреть выпуски</div>
                    </div>

                </div>
            </div>
            <div className="mobile__content">
                <div className="accordions" ref={infoBlock}>
                    <Accordion
                        heading='Как появился проект?'
                    >
                        <div className="accordion__content popup-parent">
                            <div className="author">
                                <div className="author__img">
                                    <img src={Author} alt="" loading="lazy" />
                                </div>
                                <div className="author__content">
                                    <img src={AuthorName} alt="" loading="lazy" />
                                    <p>Создатель «Тюремного вестника»</p>
                                </div>
                            </div>
                            <p>
                                Проект появился весной 2022, когда я узнал, что посадили моего знакомого, причём о&nbsp;его заключении
                                я узнал только через полгода. И я подумал, а&nbsp;что со&nbsp;мной будет, если меня посадят? Про меня так же,
                                как про моего приятеля, быстро все забудут, и я окажусь в информационной блокаде. Тогда я решил создать
                                проект, который будет помогать политзаключенным быть в контексте происходящего на воле.
                            </p>
                            <p>
                                Сначала я делал журнал для одного человека, а&nbsp;когда стало понятно, что идея рабочая, собрал команду, и&nbsp;мы
                                постепенно увеличили тираж до практически всех политзэков. Весной 2023 про нас написало несколько СМИ, что
                                помогло наладить фандрайзинг и вывело проект на самоокупаемость. Сейчас я&nbsp;практически делегировал все процессы
                                и выступаю только в роли издателя. Другими словами, у&nbsp;меня получилось создать маленькую институцию, которая будет
                                работать даже без моего участия, если с&nbsp;мной вдруг что-то случится.
                            </p>
                            <p>
                                Наша цель —  дать <a href="javascript:void(0)" className='link'>
                                    <Popup device='mobile'>
                                        Кого считать политзэками — дискуссионный вопрос. Мы каждый месяц уточняем списки.
                                </Popup>каждому политзэку</a> понимание происходящего на воле.
                            </p>
                        </div>
                    </Accordion>
                    <Accordion
                        heading='Как происходит рассылка?'
                    >
                        <div className="accordion__content">
                            <div>
                                <img src={Sending} alt="" loading="lazy" />
                            </div>
                            <div>1. Составляем списки политзаключённых</div>
                            <div>2. Отправляем журнал бумажным письмом с конвертом для обратного ответа</div>
                            <div>3. На основании ответов корректируем список</div>
                            <div>4. Собираем аналитику по зонам, где пропускают, а где нет,
                                чтобы дальше юридически влиять на цензоров</div>
                        </div>
                    </Accordion>
                    <Accordion
                        heading='Что с цензурой?'
                    >
                        <div className="accordion__content">
                            <p>
                                Она есть, поэтому мы пытаемся писать таким языком, чтобы соблюдать законы РФ.
                            </p>
                            <p>
                                Есть колонии, где не&nbsp;пропускают письма с&nbsp;любыми формулировками. Мы&nbsp;собираем статистику
                                по&nbsp;таким учреждениям для&nbsp;дальнейших судов с&nbsp;цензорами.
                            </p>
                        </div>

                    </Accordion>
                    <Accordion
                        heading='Есть ли уже ответы?'
                    >
                        <div className="accordion__content">
                            <p>
                                Да, и много. Всем очень заходит идея проекта,
                                и политзэки просят присылать им каждый месяц новый выпуск.
                                Ответы можно почитать в моём <a href='https://t.me/LosevP' target='_blank' className='link link__outer'>телеграм-канале</a> в комментариях к
                                выпускам.
                            </p>
                        </div>

                    </Accordion>
                    <Accordion
                        heading='Что по деньгам?'
                    >
                        <div className="accordion__content">
                            <p>
                                Сначала оплачивал всё из своего кармана, но потом тиражи начали расти, и я стал просить
                                денег у своих друзей и знакомых. Сейчас мы налаживаем фандрайзинг и планируем развиваться на донаты.
                        </p>
                        </div>

                    </Accordion>
                    <Accordion

                        heading='Как помочь проекту?'
                    >
                        <div className="accordion__content">
                            <div>
                                <h5 className="heading h5">
                                    Финансово
                                </h5>
                                <div className="accordion__text">
                                <p>
                                    Себестоимость одного выпуска&nbsp;— примерно 150&nbsp;рублей в&nbsp;месяц. Деньги идут на&nbsp;печать, бумагу, марки, плату за&nbsp;упаковку,
                                    конверты, менеджмент, обложку и прочее. Нам каждый месяц нужно больше средств, но не потому, что наши запросы растут,
                                    а&nbsp;потому что, к&nbsp;сожалению, политзаключённых с&nbsp;каждым месяцем становится всё больше. Донорам ежемесячно предоставляется
                                    отчёт до последней копейки.
                                </p>
                                <p>
                                    Если вы хотите поспособствовать развитию
                                    проекта, то <a href='https://t.me/PTRLS' target='_blank' className='link link__outer'>напишите мне</a>.
                                </p> 
                                </div>
                                
                            </div>
                            <div>
                                <h5 className="heading h5">
                                    Купить наши стикеры!
                                </h5>
                                <div className="accordion__text">
                                   <div>
                                    <img src={Stickers} alt='Наш стикерпак' loading="lazy" />
                                </div>
                                <p>
                                    Это поможет нам развиваться, а&nbsp;у&nbsp;вас будут уникальные наклейки, которые смогут украсить любое ваше устройство 
                                    и&nbsp;не&nbsp;только. Если вам понравятся наши стикеры, то&nbsp;мы сделаем целый стикербук! 
                                </p>
                                <p>
                                    По цене и всем подробностям приобретения <a href='https://t.me/bashka_tova' target='_blank' className='link link__outer'>пишите сюда</a>.
                                </p>
                                <p className='text-s'>
                                    * С каждого набора идет процент нашему художнику, который рисует нам великолепные обложки.
                                </p> 
                                </div>
                                
                            </div>
                            <div>
                                <h5 className="heading h5">
                                    Рассказать о проекте!
                                </h5>
                                <p>
                                    Это поможет привлечь доноров, увеличить
                                    тираж и приблизиться к&nbsp;главной цели — охватить всех
                                    политзэков России и прорвать информационную блокаду для них.
                                </p>
                            </div>
                        </div>

                    </Accordion>
                    <Accordion
                        heading='Контакты'
                    >
                        <div className="accordion__content">
                            <div>
                                <h5 className="heading h5 h5-link__outer"><a className='link link__outer' href='https://t.me/PTRLS' target='_blank'>Написать мне</a></h5>
                                <p>Оформить подписку или задать вопрос</p>
                            </div>
                            <div>
                                <h5 className="heading h5 h5-link__outer"><a className='link link__outer' href='https://t.me/LosevP' target='_blank'>Мой телеграм-канал</a></h5>
                                <p>Можно не только посмотреть выпуски, но и почитать ответы политзэков</p>
                            </div>
                        </div>
                    </Accordion>
                    <div className="divider"></div>
                </div>
                <div className="mobile__mags__header" ref={magsBlock}>
                    <div className="mobile__mags__header__heading heading">Выпуски</div>
                    <div className="mobile__mags__header__arrow"></div>
                    <img src={Arrowhead} alt='' className="mobile__mags__header__arrowhead" loading="lazy" />
                </div>
                <div className="mobile__mags__container">
                    <div className="mobile__mags">
                        {
                            mags.sort((a, b) => (b.id - a.id)).map(m => (
                                <Cover mag={m} />
                            ))
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Mobile
