import React from 'react'
import { useState, useRef } from 'react'
import ArrowSmall from '../media/arrow_small.svg'

import Plus from '../components/Plus'

const Accordion = ({ children, heading }) => {

    const [isOpen, setIsOpen] = useState(false)
    const body = useRef()

    const handleOpen = () => {
        setIsOpen(prev => !prev)
    }

    return (
        <div className='accordion'>
            <div className='accordion__header' onClick={handleOpen}>
                <div className='heading'>{heading}</div>
                <div>
                    <Plus isOpen={isOpen} />
                </div>
            </div>
            <div
                ref={body}
                className={isOpen ? 'accordion__body accordion__body__open' : 'accordion__body accordion__body__closed'}
                style={isOpen ? { height: body.current.scrollHeight } : { height: 0 }}
            >
                <div className="accordion__divider"></div>
                {children}
            </div>
        </div>
    )
}

export default Accordion
