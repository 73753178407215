const mags = [
    {
        month: 'Октябрь',
        year: '2022',
        id: 1,
        img: require('./covers/2022_10_october.jpg'),
        url: require('./mags/2022_10_october.pdf'),
    },
    {
        month: 'Ноябрь',
        year: '2022',
        id: 2,
        img: require('./covers/2022_11_november.jpg'),
        url: require('./mags/2022_11_november.pdf'),
    },
    {
        month: 'Декабрь',
        year: '2022',
        id: 3,
        img: require('./covers/2022_12_december.jpg'),
        url: require('./mags/2022_12_december.pdf'),
    },
    {
        month: 'Январь',
        year: '2023',
        id: 4,
        img: require('./covers/2023_01_january.jpg'),
        url: require('./mags/2023_01_january.pdf'),
    },
    {
        month: 'Февраль',
        year: '2023',
        id: 5,
        img: require('./covers/2023_02_february.jpg'),
        url: require('./mags/2023_02_february.pdf'),
    },
    {
        month: 'Март',
        year: '2023',
        id: 6,
        img: require('./covers/2023_03_march.jpg'),
        url: require('./mags/2023_03_march.pdf'),
    },
    {
        month: 'Апрель',
        year: '2023',
        id: 7,
        img: require('./covers/2023_04_april.jpg'),
        url: require('./mags/2023_04_april.pdf'),
    },
    {
        month: 'Май',
        year: '2023',
        id: 8,
        img: require('./covers/2023_05_may.jpg'),
        url: require('./mags/2023_05_may.pdf'),
    },
    {
        month: 'Июнь',
        year: '2023',
        id: 9,
        img: require('./covers/2023_06_june.jpg'),
        url: require('./mags/2023_06_june.pdf'),
    },
    {
        month: 'Июль',
        year: '2023',
        id: 10,
        img: require('./covers/2023_07_july.jpg'),
        url: require('./mags/2023_07_july.pdf'),
    },
    {
        month: 'Август',
        year: '2023',
        id: 11,
        img: require('./covers/2023_08_august.jpg'),
        url: require('./mags/2023_08_august.pdf'),
    },
    {
        month: 'Сентябрь',
        year: '2023',
        id: 12,
        img: require('./covers/2023_09_september.jpg'),
        url: require('./mags/2023_09_september.pdf'),
    },
{
        month: 'Октябрь',
        year: '2023',
        id: 13,
        img: require('./covers/2023_10_october.jpg'),
        url: require('./mags/2023_10_october.pdf'),
    },
{
        month: 'Ноябрь',
        year: '2023',
        id: 14,
        img: require('./covers/2023_11_november.jpg'),
        url: require('./mags/2023_11_november.pdf'),
    },
{
        month: 'Декабрь',
        year: '2023',
        id: 15,
        img: require('./covers/2023_12_december.jpg'),
        url: require('./mags/2023_12_december.pdf'),
    },
{
        month: 'Январь',
        year: '2024',
        id: 16,
        img: require('./covers/2024_01_january.jpg'),
        url: require('./mags/2024_01_january.pdf'),
    },
{
        month: 'Февраль',
        year: '2024',
        id: 17,
        img: require('./covers/2024_02_february.jpg'),
        url: require('./mags/2024_02_february.pdf'),
    },
{
        month: 'Март',
        year: '2024',
        id: 18,
        img: require('./covers/2024_03_march.jpg'),
        url: require('./mags/2024_03_march.pdf'),
    },
{
        month: 'Апрель',
        year: '2024',
        id: 19,
        img: require('./covers/2024_04_april.jpg'),
        url: require('./mags/2024_04_april.pdf'),
    },
{
        month: 'Май',
        year: '2024',
        id: 20,
        img: require('./covers/2024_05_may.jpg'),
        url: require('./mags/2024_05_may.pdf'),
    },
{
        month: 'Июнь',
        year: '2024',
        id: 21,
        img: require('./covers/2024_06_june.jpg'),
        url: require('./mags/2024_06_june.pdf'),
    },
{
        month: 'Июль',
        year: '2024',
        id: 22,
        img: require('./covers/2024_07_july.jpg'),
        url: require('./mags/2024_07_july.pdf'),
    },
{
        month: 'Август',
        year: '2024',
        id: 23,
        img: require('./covers/2024_08_august.jpg'),
        url: require('./mags/2024_08_august.pdf'),
    },
]

export default mags